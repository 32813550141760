import { watch } from 'vue'
import { useAuthStore } from '../stores/auth'

type TCallableFn = () => void | Promise<void | unknown>

export default (
  callableFnAuth: TCallableFn | undefined,
  callableFnLogout?: TCallableFn
) => {
  const authStore = useAuthStore()

  watch(
    () => authStore.isAuthenticated,
    () => {
      if (authStore.isAuthenticated && callableFnAuth) {
        callableFnAuth()
      } else {
        if (callableFnLogout) {
          callableFnLogout()
        }
      }
    },
    { immediate: true }
  )
}
