import type { IWLServicesVisible } from '../models/WhiteLabel'
import { httpClient } from '../plugins/httpClient'

export default {
  async fetchServicesVisible(domain?: string) {
    const response = await httpClient.get<IWLServicesVisible>('/wl-services', {
      params: { domain }
    })

    return response.data
  },

  async fetchPublicServicesVisible(domain?: string) {
    const response = await httpClient.get<IWLServicesVisible>(
      '/public/wl-services',
      { params: { domain } }
    )

    return response.data
  }
}
