import { useAuthStore } from '~common/stores/auth'
import useWhitelabelStore from '~/stores/whiteLabel'
import { computed, reactive, type Reactive } from 'vue'
import type { IMenuItem } from '~/models/NavigationList'

import useWhiteLabel from '~/composables/useWhiteLabel'
import { useI18n } from '~/common/src/composables/useI18n'
import useWhiteLabelStore from '~/stores/whiteLabel'
import type { IWLItemLight } from '~/models/WhiteLabel'

const buccketUrl = import.meta.env.PUBLIC_BUCCKET_URL

export const LAST_WL = 'lastWL'

export default () => {
  const authStore = useAuthStore()
  const whiteLabelStore = useWhitelabelStore()
  const whiteLabelStoreStore = useWhiteLabelStore()

  const { activeWhiteLabel, generateWLPath } = useWhiteLabel()
  const { t } = useI18n()

  const currentWl = computed(() => {
    if (activeWhiteLabel.value) {
      return activeWhiteLabel.value
    }

    const lastWlId = localStorage.getItem(LAST_WL)

    if (lastWlId) {
      const wlItem = whiteLabelStore.wlItems.find(
        item => item.id === parseInt(lastWlId, 10)
      )

      if (wlItem) {
        return wlItem
      }
    }

    return whiteLabelStore.wlItems[0]
  })

  //const isStage = import.meta.env.MODE !== 'production'

  const globalMenuList = computed<IMenuItem[]>(() => {
    const list: IMenuItem[] = []

    if (authStore.adminPermissions.is_super_admin) {
      list.push({
        rootPath: '/users',
        defaultPath: '/users',
        title: t('admin.navigation.global.users'),
        icon: 'users'
      })

      list.push({
        rootPath: '/operations',
        defaultPath: '/operations',
        title: t('admin.navigation.global.operations'),
        icon: 'operations'
      })

      list.push({
        rootPath: '/settings',
        defaultPath: '/settings/app',
        title: t('admin.navigation.global.settings'),
        icon: 'settings'
      })

      list.push({
        rootPath: '/groups',
        defaultPath: '/groups/list',
        title: t('admin.navigation.global.groups'),
        icon: 'com:account'
      })
    }

    list.push({
      rootPath: '/payment-providers',
      defaultPath: '/payment-providers',
      title: t('admin.navigation.global.paymentProviders'),
      icon: 'com:user-group-outline'
    })

    if (authStore.adminPermissions.is_super_admin) {
      list.push({
        rootPath: '/clearing',
        defaultPath: '/clearing/balances',
        title: t('admin.navigation.global.clearing'),
        icon: 'scales'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_analytics ||
      authStore.adminPermissions.has_read_access_to_balances ||
      authStore.adminPermissions.has_write_access_to_balances
    ) {
      list.push({
        rootPath: '/analytics',
        defaultPath: '/analytics/balances/users',
        title: t('admin.navigation.global.analytics'),
        icon: 'analytics'
      })
    }

    if (authStore.adminPermissions.is_super_admin) {
      list.push({
        rootPath: '/admins',
        defaultPath: '/admins/list',
        title: t('admin.navigation.global.admins'),
        icon: 'admin'
      })

      list.push({
        rootPath: '/openapi',
        defaultPath: '/openapi',
        title: t('admin.navigation.global.openapi'),
        icon: 'com:uikit'
      })

      list.push({
        rootPath: '/wl/create',
        defaultPath: '/wl/create',
        title: t('admin.navigation.global.wlCreate'),
        icon: 'com:deposit'
      })
    }

    return list
  })

  const wlList = computed(() => {
    const wlList: IMenuItem[] = []

    if (
      authStore.adminPermissions.has_read_access_to_users ||
      authStore.adminPermissions.has_write_access_to_users ||
      authStore.adminPermissions.has_read_access_to_kyc ||
      authStore.adminPermissions.has_write_access_to_kyc ||
      authStore.adminPermissions.has_read_access_to_kyc_kgz ||
      authStore.adminPermissions.has_write_access_to_kyc_kgz ||
      authStore.adminPermissions.has_read_access_to_own_referrals_users
    ) {
      wlList.push({
        rootPath: '/users',
        defaultPath: '/users',
        title: t('admin.navigation.wl.users'),
        icon: 'users'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_ledgers ||
      authStore.adminPermissions.has_read_access_to_withdrawals ||
      authStore.adminPermissions.has_write_access_to_withdrawals ||
      authStore.adminPermissions.has_read_access_to_swift ||
      authStore.adminPermissions.has_read_access_to_trades ||
      authStore.adminPermissions.has_write_access_to_swift ||
      authStore.adminPermissions.has_read_access_to_visa_withdrawals ||
      authStore.adminPermissions.has_write_access_to_visa_withdrawals ||
      authStore.adminPermissions.has_read_access_to_p2p ||
      authStore.adminPermissions.has_write_access_to_p2p ||
      authStore.adminPermissions.has_read_access_to_own_referrals_ledgers ||
      authStore.adminPermissions.has_read_access_to_bank_transfers ||
      authStore.adminPermissions.has_write_access_to_bank_transfers
    ) {
      wlList.push({
        rootPath: '/operations',
        defaultPath: '/operations',
        title: t('admin.navigation.wl.operations'),
        icon: 'operations'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_wl_settings ||
      authStore.adminPermissions.has_write_access_to_wl_settings ||
      authStore.adminPermissions.has_read_access_to_options ||
      authStore.adminPermissions.has_write_access_to_options ||
      authStore.adminPermissions.has_read_access_to_deposits ||
      authStore.adminPermissions.has_write_access_to_deposits ||
      authStore.adminPermissions.has_read_access_to_borrowings ||
      authStore.adminPermissions.has_write_access_to_borrowings ||
      authStore.adminPermissions.has_read_access_to_wl_services
    ) {
      wlList.push({
        rootPath: '/settings',
        defaultPath: `/settings`,
        title: t('admin.navigation.wl.settings'),
        icon: 'com:settings'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_investment ||
      authStore.adminPermissions.has_write_access_to_investment
    ) {
      wlList.push({
        rootPath: '/investment',
        defaultPath: '/investment/products',
        title: t('admin.navigation.wl.investment'),
        icon: 'investment'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_clearing ||
      authStore.adminPermissions.has_write_access_to_clearing
    ) {
      wlList.push({
        rootPath: '/clearing',
        defaultPath: '/clearing/balances',
        title: t('admin.navigation.wl.clearing'),
        icon: 'scales'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_operator ||
      authStore.adminPermissions.has_write_access_to_operator ||
      authStore.adminPermissions.has_read_access_to_bank_transfers ||
      authStore.adminPermissions.has_write_access_to_bank_transfers
    ) {
      wlList.push({
        rootPath: '/operator',
        defaultPath: '/operator',
        title: t('admin.navigation.wl.operator'),
        icon: 'operator'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_contracts ||
      authStore.adminPermissions.has_write_access_to_contracts
    ) {
      wlList.push({
        rootPath: '/documents',
        defaultPath: '/documents/contracts',
        title: t('admin.navigation.wl.documents'),
        icon: 'com:documents'
      })
    }

    if (
      authStore.adminPermissions.is_super_admin ||
      authStore.adminPermissions.has_read_access_to_admins
    ) {
      wlList.push({
        rootPath: '/admins',
        defaultPath: '/admins/list',
        title: t('admin.navigation.wl.admins'),
        icon: 'admin'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_analytics ||
      authStore.adminPermissions.has_read_access_to_balances ||
      authStore.adminPermissions.has_write_access_to_balances
    ) {
      wlList.push({
        rootPath: '/analytics',
        defaultPath: '/analytics/balances/users',
        title: t('admin.navigation.wl.analytics'),
        icon: 'analytics'
      })
    }

    if (
      authStore.adminPermissions.has_read_access_to_emails ||
      authStore.adminPermissions.has_write_access_to_emails
    ) {
      wlList.push({
        rootPath: '/emails',
        defaultPath: '/emails',
        title: t('admin.navigation.wl.emails'),
        icon: 'email-config'
      })
    }

    return wlList
  })

  const wlMenuList = computed(() => {
    const list: IMenuItem[] = []

    list.push({
      rootPath: `https://${currentWl.value?.domain}`,
      defaultPath: `https://${currentWl.value?.domain}`,
      title: t('admin.navigation.wl.app'),
      icon: 'dashboard',
      external: true
    })

    const normalizedWlList = wlList.value.map(item => ({
      ...item,
      rootPath: item.rootPath
        ? generateWLPath(item.rootPath, currentWl.value?.id)
        : item.rootPath,
      defaultPath: item.defaultPath
        ? generateWLPath(item.defaultPath, currentWl.value?.id)
        : item.rootPath
    }))

    return [...normalizedWlList, ...list]
  })

  const wlMenuItems = computed<Reactive<IMenuItem & IWLItemLight>[]>(() => {
    return whiteLabelStoreStore.wlItems.map(item => {
      const rootPath = `/wl/${item.id}`
      const firstItemDefaultPath = wlList.value[0].defaultPath

      return reactive({
        ...item,
        title: item.short_name,
        rootPath,
        defaultPath: firstItemDefaultPath
          ? generateWLPath(firstItemDefaultPath, item.id)
          : rootPath,
        iconUrl: `${buccketUrl}/wl/${item.domain}/logos/footer.svg`,
        icon: undefined
      })
    })
  })

  const activeWhiteLabelMenuItem = computed(() => {
    return wlMenuItems.value.find(
      ({ title }) => title === currentWl.value?.short_name
    )
  })

  const globalBlockIsShown = computed(
    () =>
      authStore.adminPermissions.is_super_admin ||
      authStore.adminPermissions.has_read_access_to_investment ||
      authStore.adminPermissions.has_write_access_to_investment ||
      authStore.adminPermissions.has_read_access_to_analytics ||
      authStore.adminPermissions.has_read_access_to_balances ||
      authStore.adminPermissions.has_write_access_to_balances
  )

  return {
    globalMenuList,
    wlMenuList,
    currentWl,
    wlMenuItems,
    activeWhiteLabelMenuItem,
    globalBlockIsShown
  }
}
