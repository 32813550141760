import generateId from '../utils/nanoid'
import type { IWebsocketMessage } from '../models/Websocket'

interface IWebsocketSubscriber {
  id: string
  channel: string
  isPublic: boolean
  callback: (data: IWebsocketMessage) => void
}

export function createWebSocketApi() {
  return {
    url: undefined as string | undefined,
    token: undefined as string | undefined,
    socket: undefined as WebSocket | undefined,
    subscribers: {} as Record<string, IWebsocketSubscriber[]>,
    reconnectTimeout: undefined as NodeJS.Timeout | undefined,
    needReconnect: true,

    // !!! Необходимо запускать первым при инициализации плагина websocket
    setWebsocketUrl(payload: string) {
      this.url = payload
    },

    setToken(token: string | undefined) {
      const oldToken = this.token

      this.token = token

      if (oldToken) {
        return
      }

      if (this.socket && this.socket.readyState === 1) {
        Object.keys(this.subscribers).forEach(channel => {
          if (this.subscribers[channel].length > 0) {
            const isPublic = this.subscribers[channel][0].isPublic

            if (!isPublic) {
              this.socket?.send(
                JSON.stringify({
                  action: 'subscribe',
                  data: {
                    access_token: this.token,
                    channel
                  }
                })
              )
            }
          }
        })
      }
    },

    connect() {
      if (!this.url) {
        throw new Error('Invalid websocket url')
      }

      if (this.socket) {
        this.socket.close()
      }

      this.socket = new WebSocket(this.url)

      this.needReconnect = true

      this.socket.addEventListener('open', () => {
        Object.keys(this.subscribers).forEach(channel => {
          if (this.subscribers[channel].length > 0) {
            const isPublic = this.subscribers[channel][0].isPublic

            if (isPublic || this.token) {
              this.socket?.send(
                JSON.stringify({
                  action: 'subscribe',
                  data: {
                    access_token: isPublic ? undefined : this.token,
                    channel
                  }
                })
              )
            }
          }
        })
      })

      this.socket.addEventListener('message', event => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let message: any // - здесь может быть всё что угодно

        try {
          message = JSON.parse(event.data)
        } catch {
          message = event.data
        }

        if (message === 'authentication error') {
          this.socket?.close()
        }

        if (
          !message.channel ||
          message.data.connectionId ||
          message.data.message
        ) {
          return
        }

        this.subscribers[message.channel]?.forEach(
          (s: IWebsocketSubscriber) => {
            if (s.callback) {
              s.callback({
                ...message,
                event: message?.event || message.data.event
              })
            }
          }
        )
      })

      this.socket.addEventListener('close', () => {
        if (this.reconnectTimeout) {
          clearTimeout(this.reconnectTimeout)
        }

        this.reconnectTimeout = setTimeout(() => {
          if (!this.socket) {
            return
          }

          if (this.socket.readyState <= 1 || !this.needReconnect) return

          console.log('close connection')
          this.connect()
        }, 10000)
      })

      this.socket.addEventListener('error', error => {
        console.error('bitbanker socket error', error)

        if (this.reconnectTimeout) {
          clearTimeout(this.reconnectTimeout)
        }

        this.reconnectTimeout = setTimeout(() => {
          if (!this.socket) {
            return
          }

          if (this.socket.readyState > 1 || !this.needReconnect) return

          console.log('error connection')
          this.connect()
        }, 10000)
      })
    },

    disconnect() {
      if (this.socket) {
        this.needReconnect = false
        this.socket.close()

        this.socket = undefined
      }

      this.subscribers = {}
    },

    subscribe(
      channel: string,
      callback: (data: IWebsocketMessage) => void,
      isPublic = false
    ) {
      this.subscribers[channel] = this.subscribers[channel] || []

      const channelSubscribers = this.subscribers[channel]
      const id = generateId()

      if (
        channelSubscribers.length === 0 &&
        this.socket &&
        this.socket.readyState === 1 &&
        (isPublic || this.token)
      ) {
        this.socket.send(
          JSON.stringify({
            action: 'subscribe',
            data: { access_token: isPublic ? undefined : this.token, channel }
          })
        )
      }

      channelSubscribers.push({ id, channel, callback, isPublic })

      return { id, channel }
    },

    unsubscribe(channel: string, id?: string) {
      const channelSubscribers = this.subscribers[channel]

      if (channelSubscribers === undefined) {
        return
      }

      const index = channelSubscribers.findIndex(s => s.id === id)

      if (index >= 0) {
        channelSubscribers.splice(index, 1)
      }

      if (
        channelSubscribers.length === 0 &&
        this.socket &&
        this.socket.readyState === 1
      ) {
        this.socket.send(
          JSON.stringify({
            action: 'unsubscribe',
            data: { channel }
          })
        )
      }
    }
  }
}

const api = createWebSocketApi()

export type TSubscribed = ReturnType<typeof api.subscribe>

export default api
