import { defineRule } from 'vee-validate'

import {
  between,
  digits,
  email,
  integer,
  length,
  max,
  max_value,
  min,
  min_value,
  numeric,
  regex,
  required,
  url
} from '@vee-validate/rules'
import { useI18n } from '../composables/useI18n'

import { isValidPhoneNumber } from 'libphonenumber-js'
import type { CountryCode } from 'libphonenumber-js/core'

let $t: (code: string, params?: Record<string, string | number>) => string

defineRule('length', length)
defineRule('numeric', numeric)
defineRule('integer', integer)
defineRule('regex', regex)

type BetweenParamsExtend =
  | [string | number, string | number, string, string]
  | {
      min: number | string
      max: number | string
      minFormated: string
      maxFormated: string
      currency: string
    }

type BetweenParams =
  | [string | number, string | number]
  | {
      min: number | string
      max: number | string
    }

type TTranslateParam = string[] | number[] | Record<string, number | string>

const t = (code: string, params?: Record<string, string | number>) => {
  $t = $t || useI18n().t

  return $t(code, params)
}

function getCustomTranslateParam(
  params: TTranslateParam,
  deft: string,
  partTranslateCode = 'common.validator.'
) {
  if (typeof params === 'object') {
    const p = params as Record<string, number | string>

    for (const property in p) {
      if (property === 'tr') {
        return partTranslateCode + p[property]
      }
    }
  } else if (Array.isArray(params)) {
    const typedParams = params as string[] | number[]

    for (const param of typedParams) {
      const normalizedParam = String(param)

      if (normalizedParam.startsWith('tr-')) {
        return partTranslateCode + normalizedParam.substring(3)
      }
    }
  }

  return partTranslateCode + deft
}

// https://ru.wikipedia.org/wiki/%D0%90%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC_%D0%9B%D1%83%D0%BD%D0%B0
function luhnCheck(nums: string) {
  let sum = 0

  const newNum = nums.split('')
  const reversed = newNum.reverse().map(Number)

  for (let i = 1; i < reversed.length; i += 2) {
    reversed[i] = reversed[i] * 2

    if (reversed[i] > 9) {
      const smallArr = reversed[i].toString().split('').map(Number)

      smallArr.forEach(i => {
        sum += i
      })
    }
  }

  for (let i = 0; i < reversed.length; i++) {
    if (reversed[i] <= 9) {
      sum += reversed[i]
    }
  }

  return sum % 10 === 0
}

defineRule('between', (value: unknown, params: BetweenParamsExtend, ctx) => {
  let max, min: string
  let currency = ''

  if ('max' in params) {
    max = params.maxFormated
    min = params.minFormated
    currency = params.currency || ''
  } else {
    min = String(params[2] || '')
    max = String(params[3] || '')
  }

  return (
    between(value, params as BetweenParams) ||
    t(getCustomTranslateParam(params as TTranslateParam, 'between'), {
      field: ctx.field,
      min,
      max,
      currency
    })
  )
})

/**
 * Минимальной значение числового поле
 */
defineRule('min', (value: string, params: [string], ctx) => {
  return (
    min_value(value, params) ||
    t(getCustomTranslateParam(params, 'min'), {
      field: ctx.field,
      min: params[0]
    })
  )
})

/**
 * Максимальное значение числового поле
 */
defineRule('max', (value: string, params: [string], ctx) => {
  return (
    max_value(value, params) ||
    t(getCustomTranslateParam(params, 'max'), {
      field: ctx.field,
      max: params[0]
    })
  )
})

/**
 * Значение должно быть меньше чем {value}
 */
defineRule('less', (value: string, params: [string]) => {
  return (
    +value < +params[0] ||
    t(getCustomTranslateParam(params, 'less'), { value: params[0] })
  )
})

/**
 * Значение должно быть больше чем {value}
 */
defineRule('greater', (value: string, params: [string]) => {
  return (
    +value > +params[0] ||
    t(getCustomTranslateParam(params, 'greater'), { value: params[0] })
  )
})

/**
 * Максимальное значение: {value}
 */
defineRule('lessEqual', (value: string, params: [string]) => {
  return (
    max_value(value, params) ||
    t(getCustomTranslateParam(params, 'lessEqual'), { value: params[0] })
  )
})

/**
 * Минимальное значение: {value}
 */
defineRule('greaterEqual', (value: string, params: [string]) => {
  return (
    min_value(value, params) ||
    t(getCustomTranslateParam(params, 'greaterEqual'), { value: params[0] })
  )
})

/**
 * Максимальная длина поле
 */
defineRule('maxLength', (value: string, params: [string], ctx) => {
  return (
    max(value, params) ||
    t(getCustomTranslateParam(params, 'maxLength'), {
      field: ctx.field,
      length: params[0]
    })
  )
})

defineRule('minLength', (value: string, params: [string], ctx) => {
  return (
    min(value, params) ||
    t(getCustomTranslateParam(params, 'minLength'), {
      field: ctx.field,
      length: params[0]
    })
  )
})

defineRule('email', (value: string, params: string[], ctx) => {
  return (
    email(value) ||
    t(getCustomTranslateParam(params, 'email'), { field: ctx.field })
  )
})

defineRule('required', (value: string, params: string[], ctx) => {
  return (
    required(value) ||
    t(getCustomTranslateParam(params, 'required'), { field: ctx.field })
  )
})

defineRule('url', (value: string, params: string[], ctx) => {
  return (
    url(value, { pattern: 'https://.*' }) ||
    t(getCustomTranslateParam(params, 'url'), { field: ctx.field })
  )
})

/**
 * Только цифры определенной длины
 */
defineRule('digits', (value: string, params: [string], ctx) => {
  return (
    digits(value, params) ||
    t(getCustomTranslateParam(params, 'digits'), {
      field: ctx.field,
      length: params[0]
    })
  )
})

/**
 * Поле должно совпадать с переданным полем
 */
defineRule('confirmed', (value, params: string[], ctx) => {
  const target = params[0]

  return (
    value === ctx.form[target] || t(getCustomTranslateParam(params, 'confirm'))
  )
})

/**
 * Поле обязательно только если заполнено поле переданное в качестве параметра
 */
defineRule('requiredIf', (value, params: string[], ctx) => {
  const depends = params[0]

  if (required(ctx.form[depends])) {
    return (
      required(value) ||
      t(getCustomTranslateParam(params, 'required'), { field: ctx.field })
    )
  }

  return true
})

/**
 * Значение должно быть не более переданного значения/баланса
 */
defineRule(
  'fitBalance',
  (
    value: string,
    params:
      | string[]
      | number[]
      | {
          max: string | number
          tr: string
          currency: string
        }
  ) => {
    let currency: string

    if ('currency' in params) {
      currency = params.currency || ''
    } else {
      currency = String(params[2] || '')
    }

    return (
      max_value(value, params as [number | string]) ||
      t(getCustomTranslateParam(params, 'fitBalance'), { currency })
    )
  }
)

/**
 * Значение должно быть кратно {value}
 */
defineRule('multiplicity', (value: string, params: [string]) => {
  const multiplicity = +params[0]

  return (
    (multiplicity && parseFloat(value) % multiplicity === 0) ||
    multiplicity === 0 ||
    t(getCustomTranslateParam(params, 'multiplicity'), { value: multiplicity })
  )
})

defineRule('password', (value: string, params: string[], ctx) => {
  return (
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`\\=])\S{8,99}$/.test(
      value
    ) || t(getCustomTranslateParam(params, 'password'), { field: ctx.field })
  )
})

/**
 * Валидация крипто кошелька
 */
defineRule('wallet', (value: string, params: string[]) => {
  const network = params[0]

  const validators = {
    Bitcoin: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,100}$/,
    'BNB Smart Chain (BEP20)': /^0x[a-fA-F0-9]{40}$/,
    'Ethereum (ERC20)': /^0x[a-fA-F0-9]{40}$/,
    'Tron (TRC20)': /^T[a-zA-Z0-9]{33}$/,
    'Avalanche C-Chain': /^0x[a-fA-F0-9]{40}$/,
    Cosmos: /^cosmos1[a-z0-9]{38}$/,
    Ton: /^([EU])[a-zA-Z0-9-_]{47}$/
  }

  if (import.meta.env.MODE !== 'production') {
    validators.Bitcoin = /^(bc1|tb1|[13])[a-zA-HJ-NP-Z0-9]{25,100}$/
    validators.Ton = /^([EUk0])[a-zA-Z0-9-_]{47}$/
  }

  const walletValidator = validators[network as keyof typeof validators]

  if (!walletValidator || !walletValidator.test(value)) {
    return t(getCustomTranslateParam(params, 'wallet'), { network })
  }

  return true
})

defineRule('cardNumber', (value: string | undefined, params: string[]) => {
  if (!required(value)) {
    return true
  }

  if (!value) {
    return true
  }

  return (
    (/^[0-9]{16,18}$/.test(value) && luhnCheck(value)) ||
    t(getCustomTranslateParam(params, 'cardNumber'))
  )
})

function isTrue(value: unknown) {
  return value === true
}

defineRule('isTrue', (value: unknown, params: string[], ctx) => {
  return (
    isTrue(value) ||
    t(getCustomTranslateParam(params, 'required'), { field: ctx.field })
  )
})

defineRule('isTrueIf', (value, params: string[], ctx) => {
  const depends = params[0]

  if (ctx.form[depends]) {
    return (
      isTrue(value) ||
      t(getCustomTranslateParam(params, 'required'), {
        field: ctx.field
      })
    )
  }

  return true
})

defineRule('isTrueIfEmpty', (value: unknown, params: string[], ctx) => {
  const depends = params[0]

  if (ctx.form[depends] === undefined || !String(ctx.form[depends]).length) {
    return (
      isTrue(value) ||
      t(getCustomTranslateParam(params, 'required'), {
        field: ctx.field
      })
    )
  }

  return true
})

defineRule('latin', (value: string, params: string[], ctx) => {
  return (
    /^[A-Za-z0-9]+$/.test(value) ||
    t(getCustomTranslateParam(params, 'latin'), {
      field: ctx.field
    })
  )
})

defineRule('notHtml', (value: string, params: string[], ctx) => {
  return (
    !/<[^>]*>/.test(value) ||
    t(getCustomTranslateParam(params, 'notHtml'), {
      field: ctx.field
    })
  )
})

defineRule('notEmptyChars', (value: string, params: string[], ctx) => {
  return (
    value.split(' ').length <= 1 ||
    t(getCustomTranslateParam(params, 'notEmptyChars'), {
      field: ctx.field
    })
  )
})

defineRule('oneOfField', (value, params: string[], ctx) => {
  for (let i = 0; i < params.length; i++) {
    if (required(ctx.form[params[i]])) {
      return true
    }
  }

  return (
    required(value) ||
    t(getCustomTranslateParam(params, 'required'), {
      field: ctx.field
    })
  )
})

defineRule('phone', (value: string, params: string[], ctx) => {
  const defaultCountry = ((params && params.length && params[0]) ||
    'RU') as CountryCode

  if (!required(value)) {
    return true
  }

  return (
    isValidPhoneNumber(value, defaultCountry) ||
    t(getCustomTranslateParam(params, 'phone'), {
      field: ctx.field
    })
  )
})
