import { httpClient } from '~common/plugins/httpClient'

import type { IPaginationPayload, IResponseResult } from '~common/models/Api'

import type {
  IWLItem,
  IWLItemLight,
  IWLDocument,
  IWLForm,
  IWLContact,
  IWLContactForm,
  IWLWithdrawalPointForm
} from '~/models/WhiteLabel'

import { downloadFile } from '~common/utils/downloadFile'
import type { IWLWithdrawalPoint } from '~common/models/WhiteLabel'

export interface IFetchWhiteLabelsParams {
  domain?: string
}

export interface IUpdateWhiteLabelPayload {
  domain: string
  data: IWLForm
}

export interface IAddWhiteLabelDocumentPayload {
  domain: string
  file: File
}

export interface IDownloadWhiteLabelDocumentParams {
  domain: string
  file_pk: number
}

export interface ICreateWhiteLabelContactPayload {
  domain: string
  data: IWLContactForm
}

export interface IUpdateWhiteLabelContactPayload {
  domain: string
  data: IWLContact
}

export interface IDeleteWhiteLabelContactPayload {
  domain: string
  pk: number
}

export interface IDeleteWhiteLabelDocumentParams {
  domain: string
  file_pk: number
}

export interface IChangeMaintenancePayload {
  domain?: string
  on_maintenance: boolean
}

export interface IFetchWithdrawalPointsParams extends IPaginationPayload {
  domain: string
  name?: string
  country?: string
  currency?: number
  is_enabled_for_deposit?: boolean
  is_enabled_for_withdrawal?: boolean
  is_active?: boolean
  user_is_blocked?: boolean
}

export interface ICreatePointsPayload extends IWLWithdrawalPointForm {
  domain: string
}

export interface IUpdateWithdrawalPointsPayload extends IWLWithdrawalPointForm {
  id: number
}

export interface IDeleteWithdrawalPointParams {
  domain: string
  id: number
}

export default {
  async fetchWhiteLabels(params?: IFetchWhiteLabelsParams) {
    const result = await httpClient.get<IWLItemLight[]>(
      'admin/white-labels/list',
      { params }
    )

    return result
  },
  async createWhiteLabel(payload: IWLForm) {
    const result = await httpClient.post<IResponseResult>(
      'admin/white-labels/list',
      payload
    )

    return result
  },
  async fetchWhiteLabel(domain?: string) {
    const result = await httpClient.get<IWLItem>('admin/white-labels', {
      params: { domain }
    })

    return result
  },
  async updateWhiteLabel({ data, domain }: IUpdateWhiteLabelPayload) {
    const result = await httpClient.put<IResponseResult>(
      'admin/white-labels',
      data,
      { params: { domain } }
    )

    return result
  },
  async addWhiteLabelDocument({ file, domain }: IAddWhiteLabelDocumentPayload) {
    const formData = new FormData()

    formData.append('file', file, file.name)

    const res = await httpClient<IResponseResult>({
      method: 'post',
      url: 'admin/white-labels/documents',
      data: formData,
      params: { domain }
    })

    return res
  },

  async downloadWhiteLabelDocument(params: IDownloadWhiteLabelDocumentParams) {
    await downloadFile({
      url: `admin/white-labels/documents/${params.file_pk}`,
      params
    })
  },

  async fetchWhiteLabelDocumentsNames(domain?: string) {
    const result = await httpClient.get<IWLDocument[]>(
      'admin/white-labels/documents',
      { params: { domain } }
    )

    return result
  },

  async deleteWhiteLabelDocument(params: IDeleteWhiteLabelDocumentParams) {
    const result = await httpClient.delete<IResponseResult>(
      `admin/white-labels/documents/${params.file_pk}`,
      { params }
    )

    return result
  },

  async fetchWhiteLabelContacts(domain?: string) {
    const result = await httpClient.get<IWLContact[]>(
      'admin/white-labels/contacts',
      { params: { domain } }
    )

    return result
  },

  async createWhiteLabelContact({
    data,
    domain
  }: ICreateWhiteLabelContactPayload) {
    const result = await httpClient.post<IResponseResult>(
      'admin/white-labels/contacts',
      data,
      { params: { domain } }
    )

    return result
  },

  async updateWhiteLabelContact({
    data,
    domain
  }: IUpdateWhiteLabelContactPayload) {
    const result = await httpClient.put<IResponseResult>(
      'admin/white-labels/contacts',
      data,
      { params: { domain } }
    )

    return result
  },

  async deleteWhiteLabelContact(params: IDeleteWhiteLabelContactPayload) {
    const result = await httpClient.delete<IResponseResult>(
      'admin/white-labels/contacts',
      { params }
    )

    return result
  },

  async changeMaintenance(payload: IChangeMaintenancePayload) {
    const result = await httpClient.post(
      '/admin/white-labels/maintenance',
      payload
    )

    return result
  },

  async fetchWithdrawalPoints(params: IFetchWithdrawalPointsParams) {
    const result = await httpClient.get<IWLWithdrawalPoint[]>(
      'admin/white-labels/withdrawal-points',
      { params }
    )

    return result.data
  },

  async updateWithdrawalPoint(payload: IUpdateWithdrawalPointsPayload) {
    const result = await httpClient.put<IWLWithdrawalPoint>(
      '/admin/white-labels/withdrawal-points',
      payload
    )

    return result.data
  },

  async createWithdrawalPoint(payload: ICreatePointsPayload) {
    const result = await httpClient.post<IWLWithdrawalPoint>(
      '/admin/white-labels/withdrawal-points',
      payload
    )

    return result.data
  },

  async deleteWithdrawalPoint(params: IDeleteWithdrawalPointParams) {
    const result = await httpClient.delete<IResponseResult>(
      '/admin/white-labels/withdrawal-points',
      { params }
    )

    return result.data
  }
}
