import axios, { type AxiosInstance, type AxiosError } from 'axios'
import dayjs from 'dayjs'
import i18n from '../plugins/i18n'

import { useAuthStore } from '../stores/auth'
import * as Sentry from '@sentry/vue'

import type { App } from 'vue'
import useAWSWAFCaptchaAxios from '../composables/useAWSWAFCaptchaAxios'
import { useCookies } from '@vueuse/integrations/useCookies'

export interface IErrorServerData {
  code?: string
  data?: Record<string, unknown>
  message?: string
  error?: string
  detail?: string
  statusText?: string
  _originalMessage?: string
}

const cookies = useCookies()

export let httpClient: AxiosInstance

export const setAuthToken = (token?: string, type = 'Bearer') => {
  if (!httpClient) return

  if (token !== undefined && token !== '') {
    httpClient.defaults.headers.common.Authorization = `${type} ${token}`
  }
}

export const clearAuthToken = () => {
  if (!httpClient) return

  httpClient.defaults.headers.common.Authorization = false
}

export function captchaRequired(error: AxiosError<IErrorServerData>) {
  return error.response?.status === 405
}

export function setAWSWAFToken(token?: string) {
  if (token) {
    httpClient.defaults.headers.common['x-aws-waf-token'] = token

    return
  }

  if (httpClient.defaults.headers.common['x-aws-waf-token']) {
    return
  }

  const awsWafToken = cookies.get<string | undefined>('aws-waf-token')

  if (!awsWafToken) {
    return
  }

  httpClient.defaults.headers.common['x-aws-waf-token'] = awsWafToken
}

export default (app: App, options: { baseURL?: string }) => {
  httpClient = axios.create({
    withCredentials: false,
    baseURL: options.baseURL
  })

  if (process.client && window.fingerprint) {
    window.fingerprint.getVisitorId().then(value => {
      if (!httpClient) return

      httpClient.defaults.headers.common['X-Fingerprint-User-Id'] = value
    })
  }

  setAWSWAFToken()

  interface IErrorTranslateData {
    code?: string
    data?: Record<string, unknown>
  }

  async function errorHandler(
    error: AxiosError<IErrorServerData>
  ): Promise<IErrorServerData> {
    if (captchaRequired(error)) {
      const captcha = useAWSWAFCaptchaAxios()

      return captcha.renderCaptcha().then(token => {
        setAWSWAFToken(token)

        if (error.config) {
          return httpClient.request(error.config)
        }

        return Promise.reject(error)
      })
    }

    const authStore = useAuthStore()

    const translateErrorMessage = (translationData: IErrorTranslateData) => {
      const str = `common.errors.${translationData.code}`
      const data = translationData.data || {}

      return i18n.global.te(str) ? i18n.global.t(str, data) : undefined
    }

    const formatDate = (data: Record<string, unknown> | undefined) => {
      if (!data) {
        return data
      }

      for (const property in data) {
        if (typeof data[property] !== 'string') {
          continue
        }

        const prop = data[property] as string

        if (dayjs(prop).isValid()) {
          data[property] = dayjs(prop).format('DD/MM/YYYY, HH:mm:ss')
        }
      }

      return data
    }

    const normalizedError = error

    if (error.response !== undefined && error.response.status === 401) {
      if (authStore.isAuthenticated) {
        console.error('auth error', error.message)
        authStore.logout()
      }
    }

    if (error.response == null || error.response.status === undefined) {
      normalizedError.message = 'Operation temporary unavailable'
    } else if (error.response.data !== undefined) {
      let data = error.response.data

      if (
        error.response.data instanceof Blob &&
        error.response.data.type === 'application/json'
      ) {
        data = JSON.parse(await error.response.data.text())
      }

      normalizedError.code = data.code

      error.message =
        translateErrorMessage({
          code: normalizedError.code,
          data: formatDate(data.data)
        }) ||
        data.message ||
        data.error ||
        data.detail ||
        data.statusText ||
        'Unknown server error'
    }

    let captureResponse: unknown = normalizedError

    if (normalizedError.response) {
      captureResponse = {
        capture: {
          status: error.response?.status,
          data_config: JSON.stringify(error.response?.config?.data),
          data_response: JSON.stringify(error.response?.data),
          statusText: error.response?.statusText,
          params: JSON.stringify(error.response?.config?.params),
          url: error.response?.config?.url,
          code: normalizedError.code
        }
      }
    }

    Sentry.setExtra('axios_response_error', captureResponse)
    Sentry.captureException(error)

    authStore.user.is_maintenance =
      normalizedError.code === 'ApplicationTemporaryUnavailable'

    return Promise.reject(normalizedError)
  }

  httpClient?.interceptors.response.use(
    response => response,
    error => errorHandler(error)
  )

  httpClient?.interceptors.request.use(
    config => config,
    error => {
      let captureRequest: unknown = error

      if (error.request) {
        captureRequest = {
          capture: {
            status: error.request?.status,
            data: JSON.stringify(error.response?.data),
            statusText: error.response?.statusText,
            request: JSON.stringify(error.response?.request)
          }
        }
      }

      Sentry.setExtra('axios_request_error', captureRequest)
      Sentry.captureException(error.message)

      return Promise.reject(error)
    }
  )

  app.config.globalProperties.$httpClient = httpClient
}
