import { defineStore } from 'pinia'
import { customAlphabet } from 'nanoid'
import { ref, nextTick, type Component, type Ref, reactive, markRaw } from 'vue'
import type { IModal, IModalItem, IModalOptions } from '../models/Modal'

const generateId = customAlphabet(
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
  12
)

export const useModals = defineStore('modals', () => {
  const items = ref([]) as Ref<IModalItem[]>

  async function show(component: Component, options?: IModalOptions) {
    let name: string

    if (options?.name !== undefined) {
      name = options.name
    } else if (component.name !== undefined) {
      name = component.name
    } else {
      name = 'dynamic_modal_' + generateId()
    }

    let modal: IModal | undefined = items.value.find(m => m.name === name)

    if (modal === undefined) {
      modal = reactive({
        ...(options || {}),
        component: markRaw(component),
        name,
        show: false
      })

      items.value.push(modal)
    }

    await nextTick()

    modal.show = true

    return modal
  }

  function hide(name?: string) {
    const modal = items.value.find(m => m.name === name)

    if (modal !== undefined) {
      modal.show = false
    }
  }

  function remove(name?: string, isCloseBtn?: boolean) {
    const index = items.value.findIndex(v => v.name === name)

    if (index !== -1) {
      const modal = items.value[index]

      if (modal.onClose) {
        modal.onClose(isCloseBtn)
      }

      items.value.splice(index, 1)
    }
  }

  return {
    items,
    show,
    hide,
    remove
  }
})
