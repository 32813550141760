import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import type { IWLUISettings } from '../models/WhiteLabel'
import uiSettingsApi from '../api/uiSettings.api'
import { useI18n } from '../composables/useI18n'

export default defineStore('ui-settings', () => {
  const uiSettings = ref<IWLUISettings>()
  const { currentLocale } = useI18n()

  const fetchUISettings = async (domain?: string) => {
    uiSettings.value = await uiSettingsApi.fetchSettings(domain)
  }

  const companyName = computed(() => {
    if (!uiSettings.value) {
      return ''
    }

    if (uiSettings.value.companyName[currentLocale.value]) {
      return uiSettings.value.companyName[currentLocale.value]
    }

    return uiSettings.value.companyName.en
  })

  return {
    uiSettings,
    fetchUISettings,
    companyName
  }
})
