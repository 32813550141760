 
import type { RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '../stores/auth'
// @ts-ignore
import redirectsConfig from './redirects.json'

interface TRedirect {
  dest: string
  code?: number
}

export function beforeEach(to: RouteLocationNormalized) {
  // @ts-ignore
  const rule: TRedirect | undefined = redirectsConfig[to.path]

  if (rule !== undefined) {
    return rule.dest
  }

  if (to.meta.public) {
    return
  }

  if (!useAuthStore().isAuthenticated) {
    return `/auth/login?url=${to.fullPath}`
  }
}
