 
import type { RouteLocationNormalized } from 'vue-router'
// @ts-ignore
import redirectsConfig from './redirects.json'
import { useAuthStore } from '~common/stores/auth'

interface TRedirect {
  dest: string
  code?: number
}

export function beforeEach(to: RouteLocationNormalized) {
  // @ts-ignore
  const rule: TRedirect | undefined = redirectsConfig[to.path]
  const { isAuthenticated, isLoadingFirstData } = useAuthStore()

  if (rule !== undefined) {
    return rule.dest
  }

  if (to.meta.public) {
    return
  }

  if (!isAuthenticated && !isLoadingFirstData) {
    return `/auth/login?url=${to.path}`
  }

  return
}
