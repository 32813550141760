import i18n from '../plugins/i18n'
import { computed } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'
import crowdinDistributionsApi from '../api/crowdin.distributions.api'

export type TLocaleMessages = Record<string, string | Record<string, unknown>>

export interface IMyxTranslationsPayload {
  lang: string
  messages: TLocaleMessages
  crowdinFile: string
  crowdinHash: string
}

const cookieDomainName = import.meta.env.PUBLIC_COOKIE_DOMAIN
const cookieLangName = 'lang'

const crowdinHash = import.meta.env.PUBLIC_CROWDIN_HASH
const crowdinFile = import.meta.env.PUBLIC_CROWDIN_FILE
const dynCrowdinHash = import.meta.env.PUBLIC_DYN_CROWDIN_HASH
const dynCrowdinFile = import.meta.env.PUBLIC_LIVE_DATA_CROWDIN_FILE
const commonCrowdinHash = import.meta.env.PUBLIC_COMMON_CROWDIN_HASH
const commonCrowdinFile = import.meta.env.PUBLIC_COMMON_CROWDIN_FILE

const defaultLocales = [
  {
    code: 'en',
    iso: 'en-US',
    name: 'English'
  },
  {
    code: 'ru',
    iso: 'ru-RU',
    name: 'Русский'
  },
  {
    code: 'ky',
    iso: 'ky-KY',
    name: 'Кыргызча'
  }
]

const RELATED_RESOURCE_URLS: Record<string, Record<string, string>> = {
  'app.bitbanker.org': {
    landing: 'bitbanker.org'
  },
  'app.bitbanker.kg': {
    landing: 'bitbanker.kg'
  },
  'app.dev.bitbanker.org': {
    landing: 'dev.bitbanker.org'
  },
  'localhost:3001': {
    landing: 'localhost:3002'
  }
}

const setLocale = async (lang?: string) => {
  if (__IS_NUXT__) return

  const cookies = useCookies([cookieLangName])

  if (lang === undefined) {
    lang = cookies.get(cookieLangName) || window.navigator.language.slice(0, 2)

    const exist = defaultLocales.find(l => l.code === lang)

    if (!exist) {
      lang = 'ru'
    }
  }

  // don't load already using locale
  if (i18n.global.locale.value === lang) {
    return
  }

  if (!i18n.global.availableLocales.includes(lang)) {
    let translations

    try {
      translations = await loadTranslations(lang)
    } catch (_e) {
      console.warn(
        `Load translations failed. Loaded ${lang} as source "ru" strings`
      )
    }

    if (translations) {
      i18n.global.setLocaleMessage<TLocaleMessages>(lang, translations)
    }
  }

  i18n.global.locale.value = lang

  cookies.set(cookieLangName, lang, {
    path: '/',
    domain: cookieDomainName,
    maxAge: 60 * 60 * 24 * 365 // 365 day
  })

  document.querySelector('html')?.setAttribute('lang', lang)
}

const loadTranslations = async (lang: string) => {
  if (__IS_NUXT__) return

  let messages: TLocaleMessages | undefined

  if (lang !== 'ru') {
    messages = {}

    await Promise.all([
      await mixTranslations({
        lang,
        messages,
        crowdinHash: crowdinHash,
        crowdinFile: crowdinFile
      }),
      await mixTranslations({
        lang,
        messages,
        crowdinHash: commonCrowdinHash,
        crowdinFile: commonCrowdinFile
      })
    ])
  }

  if (messages === undefined) {
    const [commonData, appData] = await Promise.all([
      import('../../locales/common.json'),
      // @ts-ignore
      import('../../../../locales/app.json')
    ])

    messages = Object.assign(
      appData.default as TLocaleMessages,
      commonData.default as TLocaleMessages
    )
  }

  if (__IS_APP__) {
    await mixTranslations({
      lang,
      messages,
      crowdinHash: dynCrowdinHash,
      crowdinFile: dynCrowdinFile
    })
  }

  return messages
}

async function mixTranslations(payload: IMyxTranslationsPayload) {
  const { lang, messages, crowdinFile, crowdinHash } = payload

  try {
    const manifestData =
      await crowdinDistributionsApi.getDistributionsManifest(crowdinHash)

    const resultMessages =
      await crowdinDistributionsApi.getDistributionsContent(
        crowdinHash,
        lang,
        crowdinFile,
        manifestData?.timestamp
      )

    if (!resultMessages) return

    Object.assign(messages, resultMessages)
  } catch (e) {
    console.error('Error while loading crowdin translations', e)
  }
}

export const getLocaleUrl = (path: string, domain?: string) => {
  const locale = i18n.global.locale.value

  if (!locale || locale === 'en') {
    return domain + path
  }

  return `${domain}/${locale}${path}`
}

export const getLandingUrl = (path = '') => {
  const locale = i18n.global.locale.value

  const domain = `${window.location.protocol}//${
    RELATED_RESOURCE_URLS[
      locale === 'ky' ? 'app.bitbanker.kg' : window.location.host
    ]?.landing || 'bitbanker.org'
  }`

  return locale === 'ky' ? `${domain}${path}` : getLocaleUrl(path, domain)
}

export const useI18n = () => {
  const currentLocale = computed(() => i18n.global.locale.value)

  const t = i18n.global.t
  const te = i18n.global.te
  const tm = i18n.global.tm

  const blogUrl = computed(() => {
    const urls: Record<string, string> = {
      ru: 'https://blog.bitbanker.org/ru',
      en: 'https://blog.bitbanker.org/en'
    }

    return urls[currentLocale.value] || urls.en
  })

  return {
    setLocale,
    currentLocale,
    loadTranslations,
    getLocaleUrl,
    getLandingUrl,
    t,
    te,
    tm,
    defaultLocales,
    blogUrl
  }
}
