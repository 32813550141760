<template>
  <div>
    <component
      :is="modal.component"
      v-for="modal in modals.items"
      :key="modal.name"
      v-bind="modal.compAttrs || {}"
      v-model="modal.show"
      :name="modal.name"
      v-on="modal.compListeners || {}"
      @close="onClose(modal)"
    />
  </div>
</template>

<script setup lang="ts">
import { useModals } from '../composables/useModals'
import { provide } from 'vue'
import type { IModal } from '../models/Modal'

/* ------------------------------------------------------------------------------------------------------------------ */
const modals = useModals()

provide('modal-close', handleRemove)

function handleRemove(modalName: string | undefined, isCloseBtn?: boolean) {
  modals.remove(modalName, isCloseBtn)
}

function onClose(modal: IModal) {
  modal.show = false
}
</script>
