import { computed } from 'vue'
import { useRoute } from 'vue-router'

import useWhiteLabelStore from '~/stores/whiteLabel'

export default () => {
  const route = useRoute()

  const whiteLabelStore = useWhiteLabelStore()

  const activeWhiteLabel = computed(() => {
    const wlParam = route.params.action

    if (!wlParam && typeof wlParam !== 'string') return

    return whiteLabelStore.wlItems.find(({ id }) => id === +wlParam)
  })

  const generateWLPath = (path: string, id?: number) => {
    if (typeof id === 'number') {
      return `/wl/${id}${path}`
    }

    return activeWhiteLabel.value
      ? `/wl/${activeWhiteLabel.value?.id}${path}`
      : path
  }

  return {
    activeWhiteLabel,
    generateWLPath
  }
}
